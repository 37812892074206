import { BRACELET_SIZES, DEFAULT_SIZES, NECKLACE_SIZES, RING_SIZES } from "./size.contstants";

const CATEGORIES_WITH_TRANSLATIONS = Object.freeze({
    bracelets: "bracelet_name|A",
    necklaces: "necklace_name|A",
    earrings: "earring_name|A",
    rings: "ring_name|A",
    charms: "charm_name|A",
    watches: "watch_name|A",
    accessories: "accessories|A",
});

export const CATEGORIES_LABELS_MAP = Object.freeze({
    bracelets: { label: CATEGORIES_WITH_TRANSLATIONS.bracelets, sizes: BRACELET_SIZES },
    necklaces: { label: CATEGORIES_WITH_TRANSLATIONS.necklaces, sizes: NECKLACE_SIZES },
    earrings: { label: CATEGORIES_WITH_TRANSLATIONS.earrings, sizes: DEFAULT_SIZES },
    rings: { label: CATEGORIES_WITH_TRANSLATIONS.rings, sizes: RING_SIZES },
    charms: { label: CATEGORIES_WITH_TRANSLATIONS.charms, sizes: DEFAULT_SIZES },
    watches: { label: CATEGORIES_WITH_TRANSLATIONS.watches, sizes: DEFAULT_SIZES },
    accessories: { label: CATEGORIES_WITH_TRANSLATIONS.accessories, sizes: DEFAULT_SIZES },
});
import React, { Suspense } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { NavBar } from 'src/components/navbar/NavBar';
import { productCategoryRoutes, routes } from './productsCategoryRoutes';
import ProductList from 'src/pages/product-list.page';
import Home from 'src/pages/home.page';
import Admin from 'src/components/Admin/Admin';
import translationService from 'src/services/translation.service';
import { getRoute } from 'src/services/routes.service';
import ServiceLayer from 'src/components/service-layer/ServiceLayer';
import { Spinner } from '@nextui-org/react';
import { AnimatePresence, motion } from 'framer-motion';

const Navigation = () => {
    const location = useLocation();
    return (
        <main>
            {location.pathname !== getRoute(`order_confirmation`) && <NavBar />}
            <AnimatePresence mode="wait">
                <Suspense fallback={<Spinner />}>
                    <Routes>
                        <Route path='/:lang' element={<ServiceLayer />}>
                            {routes.map((route, index) => (
                                <Route key={index} path={route.path} element={<route.element />} />
                            ))}
                            {productCategoryRoutes.map((pcr, index) => (
                                <Route
                                    key={index}
                                    path={pcr.path}
                                    element={
                                        <ProductList 
                                            title={pcr.title} 
                                            header={pcr.header} 
                                            category={pcr.category} 
                                        />
                                    }
                                />
                            ))}
                            <Route path='uk' element={<Home />} />
                            <Route path='admin' element={<Admin />} />
                        </Route>
                        <Route path='*' element={<Navigate to={`/${translationService.getPrefferedLanguageOrDefault('uk')}`} />} />
                    </Routes>
                </Suspense>
            </AnimatePresence>
        </main>
    )
}

export default Navigation;

import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Typography } from "@mui/material";
import TvoiInput from "src/components/ui/tvoi-input";

const RenderTextField = (inputField, id, value, onChange, isPartOfArray, index, handleDelete) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', width: '100%', gap: '6px'}}>
            {id === "id" && <Typography sx={{color: '#bebebe', marginBottom: '10px'}}>ID:</Typography>}

            <Box sx={{display: 'flex', flexDirection: 'column', marginBottom: '10px', width: '100%'}}>
                {id !== "id" && <Typography sx={{color: '#bebebe'}}>{inputField.placeholder}</Typography>}
                <TvoiInput 
                    placeholder={inputField.placeholder}
                    value={value ?? ''}
                    onChange={(e) => onChange(e.target.value)}
                />
            </Box>

            {isPartOfArray && id !== 'id' &&(
                <Box
                    onClick={() => handleDelete(inputField.propertyName, index)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '13px',
                        width: '43px',
                        height: '43px',
                        cursor: 'pointer'
                    }}
                >
                    <DeleteIcon sx={{color: '#17171'}}/>
                </Box>
            )}
        </Box>
    );
}

export default RenderTextField;
import { motion } from "framer-motion";
import React from "react";

interface IPageTransitionProps { 
    children: React.ReactNode;
    className?: string;
    style?: any;
}

const TvoiPageTransition: React.FC<IPageTransitionProps> = ({children, className, style}) => {
    return (
        <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}  
            transition={{
                duration: 0.3,
                ease: "easeInOut",
            }}
            className={className}
            style={style}
        >
            {children}
        </motion.div>
    );
};

export default TvoiPageTransition
export function sanitizePhoneNumber(phone: string): string {
    let sanitizedPhone = phone.replace(/^(\+38|38)/, '');

    if (!sanitizedPhone.startsWith('0')) {
        sanitizedPhone = '0' + sanitizedPhone;
    }

    sanitizedPhone = sanitizedPhone.replace(/^0+/, '0');

    return sanitizedPhone;
}

export function generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function formatPrice(number: number) {
    if (number >= 1000) {
        const formattedNumber = (number / 1000).toFixed(3);
        return formattedNumber.replace(".", " ");
    } 
    return number.toString();
}
import { Helmet } from "react-helmet";

interface ISeoHeaderProps {
    option: string
}

export const SeoHeader = ({option}: ISeoHeaderProps): JSX.Element => {
    return(
        <Helmet>
            <title>
                {option}
            </title>
        </Helmet>
    )
}
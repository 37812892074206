import React from "react";
import { ProductContext } from "../../../../../context/product-context";
import { Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

const ButtonGroup = ({index}) => {

    const { deleteProduct } = React.useContext(ProductContext);

    return(
        <Box onClick={() => deleteProduct(index)}>
            <DeleteIcon sx={{color: '#171717'}}/>
        </Box>
    )
}

export default ButtonGroup;
import { collection, doc, getDocs, setDoc, getDoc, deleteDoc, query, where, addDoc } from "firebase/firestore";
import { db } from '../configs/firebase';
import { ALL_DATA } from 'src/data/all_data';
import { sanitizePhoneNumber } from 'src/utils/number.util';

class FirebaseService {
    public async postDataToFireStore(collectionName: string, data: any) {
        try {
            const collectionRef = collection(db, collectionName);
            const docRef = await addDoc(collectionRef, data);
        } catch (error) {
            console.error("Error adding data: ", error);
        }
    }
    
    public async updateDataInFireStore(collectionName: string, id: string, newData: any) {
        try {
            await setDoc(doc(db, collectionName, id), newData);
        } catch (error) {
            console.error("Error updating data: ", error);
        }
    }
    
    public async deleteDataFromFireStore(collectionName: string, id: string) {
        try {
            const docRef = doc(db, collectionName, id);
            await deleteDoc(docRef);
    
            console.log(`Document with ID "${id}" has been successfully deleted from collection "${collectionName}".`);
        } catch (error) {
            console.error("Error deleting data: ", error);
        }
    }
    
    public async uploadUserDataToFireStore(userData: any) {
        try {
            const sanitizedPhone = sanitizePhoneNumber(userData.phone);            
            const usersRef = collection(db, "orders");
            const phoneQuery = query(usersRef, where("phone", "==", sanitizedPhone));
            const querySnapshot = await getDocs(phoneQuery);
    
            if (!querySnapshot.empty) {
                console.log(`Skipping upload. User with phone number ${sanitizedPhone} already exists.`);
                return;
            }
    
            const userRef = doc(db, "orders", userData.phone);
    
            const dataToUpload = {
                name: userData.name,
                phone: sanitizedPhone,
                //email: userData.email,
                selectedImage: userData.selectedImage,
                town: userData.town,
                postOffice: userData.postOffice
            };
    
            await setDoc(userRef, dataToUpload);
    
            console.log("User data successfully uploaded!");
        } catch (error) {
            console.error("Error uploading user data: ", error);
        }
    }
    
    public async getUserDataFromFireStoreByPhone(phone: string) {
        try {
            const sanitizedPhone = sanitizePhoneNumber(phone);
            const userRef = doc(db, "orders", sanitizedPhone);
            const docSnap = await getDoc(userRef);
    
            if (docSnap.exists()) {
                console.log(`User with phone number ${sanitizedPhone} found.`);
                return { ...docSnap.data(), id: docSnap.id };
            }
    
            console.log(`User with phone number ${sanitizedPhone} not found.`);
            return;
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
    }
    
    public async uploadPricesToFireStore() {
        try {
            const batchUpload = ALL_DATA.map(async (item) => {
                const docRef = doc(db, "prices", item.id);
                
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log(`Skipping item with ID ${item.id}, as it already exists.`);
                    return;
                }
              
                const priceData = {
                    productId: item.id,
                    count: item.count,
                    price: item.price,
                    collection: item.collection,
                    category: item.category,
                    salePrice: item.salePrice,
                    popularity: item.popularity,
                    customPopularity: item.customPopularity,
                    weight: item.weight,
                    metal: item.metal,
                    description: item.description,
                    packaging: item.packaging,
                    brand: item.brand,
                    labelName: item.labelName,
                    images: item.images ?? [],
                    sizes: item.sizes ?? [],
                    colors: item.colors ?? [],
                    realPhotos: item.realPhotos ?? [],  
                    relatedProducts: item.relatedProducts ?? []
                };
              
                await setDoc(docRef, priceData);
                console.log(`Added item with ID ${item.id}`);
            });
          
            await Promise.all(batchUpload);
            console.log("Prices upload process completed.");
        } catch (error) {
            console.error("Error uploading prices: ", error);
        }
    }
    
    public async removeAllPricesFromFireStore() {
        try {
            const querySnapshot = await getDocs(collection(db, "prices"));
            const batchDelete = querySnapshot.docs.map(async (doc) => {
                await deleteDoc(doc.ref);
            });
          
            await Promise.all(batchDelete);
            console.log("All prices have been successfully deleted.");
        } catch (error) {
            console.error("Error removing prices: ", error);
        }
    }
}

export default FirebaseService;
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { categoriesRender } from "../../../../../route/productsCategoryRoutes";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { generateUUID } from "src/utils/number.util";
import { Box } from "@mui/material";
import TvoiButton from "src/components/ui/tvoi-button";

const AddManually = ({handleSelectChange, category, inputFields, renderInputField, productFields, handleInputChange, handleAddProduct}) => {
    const createFieldCopy = (inputField: any) => {
        const currentArray = productFields[inputField.propertyName];
        let copy: any[] = [];

        const template = Object.keys(currentArray[0]).reduce((acc, key) => {
            acc[key] = key === 'id' ? generateUUID() : '';
            return acc;
        }, {});

        copy = [...currentArray, template];
        handleInputChange(inputField.propertyName, copy);
    }

    return(
        <Box>
            <InputLabel id="category">Select Category</InputLabel>
            <Select
                size="small"
                sx={{ width: '100%', margin: '5px 0px'}}
                value={category}
                onChange={handleSelectChange}
            >
                {categoriesRender.map((option, index) => {
                    return(
                        <MenuItem key={index} value={option.path}>
                            {option.path}
                        </MenuItem>
                    )
                })}
            </Select>
            {inputFields.map((inputField) => (
                <Box key={inputField.propertyName} sx={{ position: 'relative', width: '100%' }}>
                    {renderInputField(inputField)}
                    {inputField.isArray && 
                        <>
                            <Button onClick={() => createFieldCopy(inputField)}>
                                <AddCircleIcon/>
                            </Button>
                        </>
                    }
                </Box>
            ))}
            <TvoiButton onClick={handleAddProduct} placeholder="Add Product" color="#171717" textColor="#fff"/>
        </Box>
    )
}

export default AddManually
import React from 'react'
import ProductItem from '../items/ProductItem'
import { ProductContext } from '../../../../context/product-context'
import { Box } from '@mui/material'

const ControlPanel = () => {
  const {products} = React.useContext(ProductContext)

  return (
    <Box sx={{
      overflowY: 'auto',
      height: 'calc(100vh - 50px)',
      padding: '20px',
    }}>
      <ProductItem items={products}/>
    </Box>
  )
}

export default ControlPanel
import React from 'react'
import { ProductContext } from '../../../../../context/product-context';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import AddManually from './AddManually';
import RenderTextField from '../../fields/RenderTextField';
import AddJson from './AddJson';
import { Box, Typography } from '@mui/material';

const AddTab = ({inputFields}) => {
    const [value, setValue] = React.useState(0);
    const { handleInputChange, productFields, postProduct, category, handleSelectChange } = React.useContext(ProductContext);
    
    const handleAddProduct = async () => {
        console.log('pressed')
        await postProduct();
    };

    const handleDeleteField = (fieldName: any, index: number) => {
        let copy = [...productFields[fieldName]];
        copy.splice(index, 1); 
        handleInputChange(fieldName, copy);
    }

    const renderArrayOfObjects = (propertyName: string, inputField: any) => {
        return productFields[propertyName].map((item: any, index: number) => (
            <Box key={index} sx={{display: 'flex', flexDirection: 'column'}}>

                {Object.keys(item).map((key) => (
                    <Box key={key}>
                        {RenderTextField(
                            inputField,
                            key,
                            item[key],
                            (newValue: any) => {
                                const copy = [...productFields[propertyName]];
                                copy[index][key] = newValue;
                                handleInputChange(propertyName, copy);
                            },
                            true,
                            index,
                            handleDeleteField
                        )}

                    </Box>
                ))}
                
            </Box>
        ));
    };
    
    const renderArrayOfPrimitives = (propertyName: string, inputField: any) => {
        return productFields[propertyName].map((value: any, id: number) =>
            RenderTextField(
                inputField,
                id,
                value,
                (newValue: any) => {
                    const copy = [...productFields[propertyName]];
                    copy[id] = newValue;
                    handleInputChange(propertyName, copy);
                },
                true,
                id,
                handleDeleteField
            )
        );
    };
    
    const renderNonArrayField = (propertyName: string, inputField: any) => {
        const value = productFields[propertyName] ?? '';
        
        return RenderTextField(
            inputField,
            propertyName,
            value,
            (newValue: any) => handleInputChange(propertyName, newValue),
            false,
            0,
            () => {}
        );
    };
    
    const renderInputField = (inputField: any) => {
        const propertyName = inputField.propertyName;
    
        if (inputField.isArray) {
            if (Array.isArray(productFields[propertyName]) && typeof productFields[propertyName][0] === "object") {
                return renderArrayOfObjects(propertyName, inputField);
            } else {
                return renderArrayOfPrimitives(propertyName, inputField);
            }
        } else {
            return renderNonArrayField(propertyName, inputField);
        }
    };

    return (
        <div className='flex flex-col items-center'>
            <div className='m-2 text-lg text-[#7c7c7c] w-full'>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Add Manually" icon={<BorderColorIcon />} />
                    <BottomNavigationAction label="Add Json" icon={< ContentPasteGoIcon/>} />
                </BottomNavigation>
            
            </div>
            <div className='w-full'>
                {value === 0 && <AddManually
                    handleAddProduct={handleAddProduct}
                    handleInputChange={handleInputChange}
                    handleSelectChange={handleSelectChange}
                    productFields={productFields}
                    inputFields={inputFields}
                    category={category}
                    renderInputField={renderInputField}
                />}
                {value === 1 && <AddJson/>}
            </div>
            
        </div>
    );
};

export default AddTab;
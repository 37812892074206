import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { getRoute } from '../../services/routes.service';
import { ImageBehaviour, Images } from './single-product-image';
import { Price } from './single-product-price';
import useWindowSize from '../../hooks/useWindowSize.hook';
import { forwardRef } from 'react';
import { translateText } from '../../services/translation.service';
import AdProduct from './single-product-advertisment';
import GoogleFeedAd from 'src/adsense/GoogleFeedAd';
import StarIcon from '@mui/icons-material/Star';
import productsService from 'src/services/products.service';
import { ProductContext } from 'src/context/product-context';
import ProductCardSkeleton from '../skeletons/ProductCardSkeleton';
import { IS_BLACK_FRIDAY } from 'src/constants/date.constants';
import { BLACK_GRADIENT, GREEN_GRADIENT } from 'src/constants/gradient.constants';

interface IProps {
  products: IProduct[];
  style: string;
  id?: string;
}

const CardProducts = (props: IProps, ref: React.Ref<HTMLDivElement>) => {
  const { isLoading } = React.useContext(ProductContext);
  const navigate = useNavigate();
  let size = useWindowSize();
  const isMobile = size.width < 770;

  const percentageIconBackground = IS_BLACK_FRIDAY ? BLACK_GRADIENT : GREEN_GRADIENT;

  const showAd = () => {
    return(
      !isMobile && <GoogleFeedAd/>
    )
  }

  const percentSale = (percentage: any) => {
    return (
      <div className='percentageIcon' style={{background: percentageIconBackground}}><span className='percentageNumber'>-{percentage}%</span></div>
    )
  }

  const starProduct = () => {
    return (
      <div className='starProductStyle'>
        <StarIcon style={{fontSize: 14, color: '#fff'}}/>
      </div>
    )
  }

  const freeShippingLabel = () => {
    return (
      <div className='freeShippingLabel'>
        <StarIcon style={{fontSize: 14}}/>
        <div> Безкоштовна доставка </div>
      </div>
    )
  }

  const _renderProductShares = (percentage: any, product: IProduct) => {
    const isSale = product.salePrice > 0;
    const isPopular = product.popularity === 1 && product.customPopularity === 1 && product.count > 0;
    const isShippingFree = productsService.isShippingFree(product);
    
    return (
      <>
        <div className='percentageIconPosition'>
          {isSale && percentSale(percentage)}
          {isPopular && starProduct()}
        </div>

        <div className='freeShippingLabelPosition'>
          {isShippingFree && freeShippingLabel()}
        </div>
      </>
    )
  };

  const navigateToProduct = (id: any) => {
    navigate(getRoute(`product/${id}`));
  }

  const viewProducts = props.products.map((p, index) => {
    const difference = Math.round((p.salePrice / p.price) * 100);
    const roundedDifference = Math.round(difference / 5) * 5;
    const percentage = 100 - roundedDifference

    const _renderProductCard = () => {
      return (
        <React.Fragment key={index}>
          {index > 0 && index % 15 === 0 && <AdProduct/>}
          {index > 0 && index % 12 === 0 && showAd()}
  
          <div className='productCard' onClick={() => navigateToProduct(p.id)}>
  
            <div className="productImagePos">
              {_renderProductShares(percentage, p)}
              <Images images={p.images} behaviour={ImageBehaviour.Single} />
            </div>
  
            <div className='productLabel' key={index}>
              <Price product={p} font='xl' currTypeBack={translateText("uah|A")} />
              {isMobile ? <></> : <div className='productLabelOverflow'>{translateText(p.labelName)} {p.brand}</div>}
            </div>
  
          </div>
        </React.Fragment>
      );
    }

    return isLoading ? <ProductCardSkeleton /> : _renderProductCard();
    
  })

  return (
    <div className={`${props.style}`} ref={ref}>
      {viewProducts}
    </div>
  );
}

export default forwardRef(CardProducts);

import { generateUUID } from "src/utils/number.util";

export const BRACELET_SIZES = [
    {
        value: 16,
        id: generateUUID()
    },
    {
        value: 17,
        id: generateUUID()
    },
    {
        value: 18,
        id: generateUUID()
    }
];

export const RING_SIZES = [
    {
        value: 54,
        id: generateUUID()
    },
    {
        value: 56,
        id: generateUUID()
    },
    {
        value: 58,
        id: generateUUID()
    }
];

export const NECKLACE_SIZES = [
    {
        value: '40-45cm',
        id: generateUUID()
    }
];

export const DEFAULT_SIZES = [
    {
        value: 'єдиний розмір',
        id: generateUUID()
    }
];
import { generateUUID } from "src/utils/number.util";

export const DEFAULT_PRODUCT = {
    count: 0,
    labelName: '',
    brand: '',
    metal: 'silver925|A',
    description: 'basic_desc|A',
    packaging: 'basic_packaging|A',
    price: 0,
    salePrice: 0,
    popularity: 5,
    customPopularity: 5,collection: '',
    weight: 0,
    images: [{
        url: '',
        id: ''
    }],
    colors: [
        {
            name:'silver|A',
            id: generateUUID()
        }, {
            name:'gold|A',
            id: generateUUID()
        }, {
            name:'roseGold|A',
            id: generateUUID()
        }
    ],
    sizes: [
        {
            value: '16',
            id: generateUUID()
        }
    ],
    realPhotos: [{
        url: '',
        id: generateUUID()
    }]
};
import React, { createContext, useState, useContext, ReactNode } from "react";
import { NewsTypeEnum } from "src/constants/enums/news.enum";
import { useGetDataFromFirestore } from "src/hooks/useGetDataFromFirestore.hook";

type NewsContextType = {
    news: INews[];
    getNewsByBrand: (brand: string) => INews[];
};

const NewsContext = createContext<NewsContextType | undefined>(undefined);

export const NewsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [news, setNews] = useState<INews[]>([]);

    const { data: newsData } = useGetDataFromFirestore('news');
    
    React.useEffect(() => {
        if (newsData.length > 0) {
            setNews(newsData);
        }
    }, [newsData]);

    const getNewsByBrand = (brand: string): INews[] => {
        const normalizedBrand = brand.toLowerCase();
        const filteredNews = news.filter(item => {
            const itemBrand = item.brand.toLowerCase();
            return itemBrand === normalizedBrand || itemBrand === NewsTypeEnum.important.toLowerCase();
        });
        
        filteredNews.sort((a, b) => {
            const aIsImportant = a.brand.toLowerCase() === NewsTypeEnum.important.toLowerCase();
            const bIsImportant = b.brand.toLowerCase() === NewsTypeEnum.important.toLowerCase();
    
            if ((aIsImportant && bIsImportant) || (!aIsImportant && !bIsImportant)) {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            }
            
            return aIsImportant ? -1 : 1;
        });
        
        return filteredNews;
    };      

    return (
        <NewsContext.Provider value={{ news, getNewsByBrand }}>
            {children}
        </NewsContext.Provider>
    );
};

export const useNews = (): NewsContextType => {
    const context = useContext(NewsContext);
    if (!context) {
        throw new Error("useNews must be used within a BannerProvider");
    }
    return context;
};

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastrList } from './components/toastr/ToastrList';
import ScrollToTop from './components/helpers/scroll-to-top.helper';
import { Bounce, ToastContainer } from 'react-toastify';
import Navigation from './route/navigation';
import './custom.css';

export const isAdActive = false;

export default function App() {
  return (
    <div className='App'>
      <ToastrList />
      <BrowserRouter>
        <ScrollToTop>
          <Navigation/>
        </ScrollToTop>
        <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Bounce}
          closeButton={false}
        />
      </BrowserRouter>
    </div>
  );
}

import React from 'react'
import { ImageBehaviour, Images } from '../../../single-product-card/single-product-image';
import { translateText } from '../../../../services/translation.service';
import ButtonGroup from './buttons/ButtonGroup';
import { Box, Typography } from '@mui/material';


interface IProps{
    items: IProduct[];
}

const ProductItem = (props: IProps) => (
    <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '50px',
        alignItems: 'center',
        padding: '20px',
    }}>
        {props.items.map((item, index) => {
            return(
                <Box key={index} className='w-[200px]'>
                    <Box className='h-[200px] relative'>
                        <Images images={item.images} behaviour={ImageBehaviour.Single}/>
                        <Box className='absolute bottom-2 right-2'><ButtonGroup index={item.id}/></Box>
                    </Box>
                    <Box className='bg-white px-2 py-1 text-xs flex flex-col'>
                        <Typography className='text-[#8b8b8b]'>#{item.id}</Typography>
                        <Typography>{translateText(item.labelName)} {item.brand}</Typography>
                        <Typography>{item.price} UAH</Typography>
                    </Box>
                </Box>
            )
        })}
    </Box>
)

export default ProductItem